import { ImageAspectRatio } from '@hubcms/domain-images';
import { defaultTeaserImageRatio } from '@hubcms/brand';
import type { TextOnImageTeaserProps as ChameleonTextOnImageTeaserProps } from '../../domain/chameleon/text-on-image-teaser-props';
import type { TextOnImageTeaserProps } from './types';

export function mapImageFormatToAspectRatio(
  imageFormat: TextOnImageTeaserProps['imageFormat'],
): ChameleonTextOnImageTeaserProps['imageOptions']['aspectRatio'] {
  if (typeof imageFormat === 'object') {
    return Object.entries(imageFormat).reduce((imageAspectRatio, [vp, ratio]) => {
      return {
        ...imageAspectRatio,
        [vp]: `${ImageAspectRatio[ratio].width}:${ImageAspectRatio[ratio].height}`,
      };
    }, {});
  }

  const imgFormat = imageFormat || defaultTeaserImageRatio;
  return `${ImageAspectRatio[imgFormat].width}:${ImageAspectRatio[imgFormat].height}`;
}
