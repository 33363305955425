import { TextOnImageTeaser as ChameleonTextOnImageTeaser } from '@mediahuis/chameleon-react';
import type { ComponentType, FC, ReactElement } from 'react';

import { getThemeDataAttributes } from '@hubcms/utils-theme';

import type { TextOnImageTeaserProps as ChameleonTextOnImageTeaserProps } from '../../domain/chameleon/text-on-image-teaser-props';
import type { TextOnImageTeaserProps } from './types';
import { getChameleonProps } from './getChameleonProps';
import { NativeTeaserImage } from '../NativeTeaserImage';
import { TeaserLink } from '../TeaserLink';

// eslint-disable-next-line react/jsx-no-useless-fragment
const EmptyImage: FC = () => <></>;

const TextOnImageTeaser: FC<TextOnImageTeaserProps> = props => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  let teaserImage: ReactElement = <EmptyImage />;
  if (props.image) {
    teaserImage = (
      <NativeTeaserImage
        image={props.image}
        sizes={props.imageSizes}
        format={props.imageFormat}
        isPriority={props.isImagePriority}
      />
    );
  }

  let link = props.link;
  if (typeof props.link === 'string' && props.trackingData) {
    link = <TeaserLink url={props.link} isExternal={props.isExternal} trackingData={props.trackingData} />;
  }

  const chameleonProps = getChameleonProps(props, teaserImage);

  const themeDataAttributes = props.theme ? getThemeDataAttributes(props.theme) : null;
  const Component = ChameleonTextOnImageTeaser as ComponentType<ChameleonTextOnImageTeaserProps>;
  return (
    <Component
      {...chameleonProps}
      link={link}
      data-article-id={props.id}
      data-list-id={props.list?.id}
      {...themeDataAttributes}
    />
  );
};

export default TextOnImageTeaser;
